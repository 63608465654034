<div class="multi-select-wrapper" [ngStyle]="{'cursor': disabled() ? 'not-allowed': 'auto'}">

    <div class="select-input" (click)="toggleDropdown()" [ngStyle]="{'pointer-events': disabled() ? 'none': '', 'border': (formControl?.errors && (formControl?.dirty || formControl?.touched) && !isDropDownOpen()) ? '1px solid var(--errorColor)': '1px solid var(--gray300)', 'color': selectedValues().length ? 'var(--blackColor)': ''}">
        @if(selectedValues().length){
           {{selectedItems}}
        } @else {
            {{props().placeholder}}
        }
        <i class="ph" [ngClass]="{'ph-caret-up': isDropDownOpen(), 'ph-caret-down' : !isDropDownOpen()}"></i>
    </div>

    @if (props().supressError === undefined || props().supressError === false) {
        @if(formControl){
            <div class="error-text font-weight-500" [ngClass]="{'error-text-visible': (formControl.errors && (formControl.dirty || formControl.touched) && !isDropDownOpen())}">{{ props().customError ? props().customError : 'This field is mandatory.' }}</div>
        }
    }

    @if (isDropDownOpen()) {

        <div class="options-wrapper">

            @if (props().searchPlaceholder) {
                <div class="search-wrapper">
                    <app-search [props]="{backgroundColor: 'var(--gray50)', border: '1px solid var(--whiteColor)', borderRadius: '8px', placeholder: props().searchPlaceholder, value: searchTerm()}" (onSearch)="onSearch($event)" />
                </div>
            }

            <ul class="option-list">

                @if (props().options.length) {
                    <li class="header">
                        <mat-checkbox (change)="selectAllOptions()" [checked]="(selectedValues().length > 0 && (selectedValues().length === props().options.length))" [indeterminate]="interminate" /> 
                        Select All
                        <hr>
                        {{selectedValues().length}}/{{props().options.length}}
                    </li>
                }

                @for (item of props().options; track item.value) {

                    @if (selectedValues().includes(item.value)) {
                        <li class="list-item" (click)="toggleOption($event, item)">
                            <mat-checkbox [checked]="true" /> 
                            {{item.name}}
                        </li>
                    }
                }

                @if(selectedValues().length){
                    <hr>
                }

                @if (props().options.length) {

                    @for (item of props().options; track item.value) {

                        @if (!selectedValues().includes(item.value)) {
                            <li class="list-item" (click)="toggleOption($event, item)">
                                <mat-checkbox [checked]="selectedValues().includes(item.value)" /> 
                                {{item.name}}
                            </li>   
                        }              
                    }

                } @else {
                    <li class="list-item no-item">No option found</li>
                }
            </ul>
        </div>
    }
</div>