import { NgClass, NgStyle } from '@angular/common';
import { Component, ElementRef, HostListener, Injector, input, OnInit, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { SearchComponent } from '../search/search.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

export interface Option<T> {
  name: string,
  value: T,
}

export interface MultiSelectProps<T> {
  placeholder: string,
  options: Option<T>[],
  searchPlaceholder?: string,
  supressError?: boolean,
  customError?: string
}

@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [
    NgStyle,
    SearchComponent,
    MatCheckboxModule,
    NgClass
  ],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MultiSelectComponent,
    multi: true
  }]
})

export class MultiSelectComponent<T> implements OnInit, ControlValueAccessor {

  props = input.required<MultiSelectProps<T>>();
  onOptionSearch = output<string>();
  disabled = signal<boolean>(false);
  isDropDownOpen = signal<boolean>(false);
  formControl: NgControl | null = null;
  searchTerm = signal<string>('');
  selectedValues = signal<T[]>([]);

  onChange = (value: T[]) => { }
  onTouch = () => { }

  constructor(
    private _injector: Injector,
    private _ref: ElementRef,
  ) { }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this._ref.nativeElement.contains(event.target)) {
      this.isDropDownOpen.set(false);
    }
  }

  ngOnInit(): void {
    try {
      this.formControl = this._injector.get(NgControl);
    } catch { }
  }

  toggleDropdown() {
    this.isDropDownOpen.set(!this.isDropDownOpen());
    this.onTouch();
  }

  selectAllOptions() {
    if (!(this.selectedValues().length === this.props().options.length)) {
      this.selectedValues.set(this.props().options.map((item) => { return item.value }));
    } else {
      this.selectedValues.set([]);
    }
    this.onChange(this.selectedValues());
  }

  toggleOption(event: Event, option: Option<T>) {
    event.stopPropagation();
    if (this.selectedValues().includes(option.value)) {
      this.selectedValues.update(current => [...current.filter((item) => item !== option.value)]);
    } else {
      this.selectedValues.update(current => [...current, option.value]);
    }
    this.onChange(this.selectedValues());
  }

  get interminate() {
    return (!(this.selectedValues().length === this.props().options.length) && this.selectedValues().length > 0)
  }

  get selectedItems() {
    const matchedOption = this.props().options.find(item => item.value === this.selectedValues()[0])
    return this.selectedValues().length > 1 ? `${matchedOption?.name} +${this.selectedValues().length - 1}` : matchedOption?.name
  }

  onSearch(searchTerm: string) {
    this.searchTerm.set(searchTerm);
    this.onOptionSearch.emit(this.searchTerm());
  }

  writeValue(options: T[]): void {
    if (!options) {
      return
    } else {
      this.selectedValues.set(options);
    }
  }

  registerOnChange(fn: (value: T[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

}